.home {

    ion-header {
        &.header-md:after {
            background: none;
        }
    }

    ion-header,
    ion-toolbar {

        border: none !important;
        --border-style: none !important;
        border-color: none !important;
        border-bottom: none !important;
    }

    /*ion-toolbar {

        background-color: #5893fa;
        --background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%236a9df6' fill-opacity='0.9' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }*/
}

.topHeader {

    height: 8rem;
    margin-bottom: -8rem;
    border-end-start-radius: 60px !important;

    /*background-color: #5893fa;*/
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%236a9df6' fill-opacity='0.9' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.avatar {

    border-radius: 10px;
}

.profileStats {

    background-color: rgb(245, 245, 245);
    border-radius: 10px !important;
    padding: 0.2rem;
    align-content: center;
}

.profileStat {

    text-align: center;
    margin: 0 auto;

    ion-card-title {

        color: rgb(68, 68, 68);
        font-size: 0.8rem;
    }

    ion-card-subtitle {

        font-size: 0.6rem;
    }
}

.profileInfo {

    text-align: left;
    /*margin-top: -0.2rem;*/
    justify-content: center;
    align-content: center;
    align-items: center;
}

.profileName {

    color: rgb(59, 59, 59) !important;
    font-weight: 500 !important;
}

.profileCard {

    ion-icon {

        font-size: 1.75rem;
        color: #5893fa;
        margin-bottom: 1rem;
    }
}

.profileStatusContainer {

    margin-top: -1rem;
    margin-bottom: -1rem;

    ion-card-content {

        margin-top: -1rem !important;
    }
}

.profileActionContainer {

    margin-top: -1rem;
}

.profileStatus {

    ion-card-subtitle {

        margin-top: 0.35rem;
        margin-left: 1rem;
    }
}

.profileActionCard {

    padding-top: 0.5rem;

    ion-icon {

        font-size: 1.75rem;
        color: #5893fa;
        margin-top: -0.3rem;
    }
}