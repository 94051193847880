.post {

    /*margin-top: 0.5rem !important;*/
}

.post-container {

    /*background-color: white !important;*/
}

.post-header {

    /* padding: none !important; */
    /* margin: none !important; */
}

.post-header {

    /*padding-top: 0.5rem !important;*/
    padding-left: 0.5rem !important;
    z-index: 9999;
}

.post-header ion-avatar {

    /* border: 2px solid rgb(164, 222, 255); */
}

.post-header ion-label {

    margin-left: 0.5rem !important;
    z-index: 99999 !important;
}

.post-header ion-label h3 {

    font-weight: 600 !important;
}

.post-header ion-label p ion-icon {

    position: absolute !important;
    margin-top: 0.15rem !important;
}

.post-header ion-icon {

    /*color: rgb(133, 133, 133) !important;*/
}

.post-content {

    padding-left: 0.5rem !important;
    margin-top: -1rem !important;
}

.post-link {

    padding-left: 0.5rem !important;
    /*background-color: rgb(245, 245, 245) !important;*/
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.post-link ion-button {

    margin-right: 0.5rem !important;
    /*--background: rgb(245, 245, 245) !important;*/
    /*border: 1px solid rgb(98, 98, 98) !important;*/
    border-radius: 8px !important;
    /*color: rgb(98, 98, 98) !important;*/
    text-transform: uppercase !important;
    font-size: 0.8rem !important;
}

.post-likes {

    padding-left: 0.5rem !important;
    /*padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;*/
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}

.post-like-icons {

    margin-top: 0.2rem !important;
}

.post-likes ion-icon:not(:last-child) {

    /*background-color: rgb(70, 128, 255) !important;*/
}

.post-likes ion-icon:last-child {

    /*background-color: rgb(255, 76, 76) !important;*/
    margin-left: -0.5rem !important;
}

.post-likes ion-icon {

    /*color: white !important;*/
    border-radius: 100px !important;
    padding: 0.2rem !important;
    border: 2px solid /*white*/ !important;
}

.post-likes p {

    padding: none !important;
    margin: none !important;
    margin-top: 0.4rem !important;
    font-size: 0.8rem !important;
    margin-right: 0.5rem !important;
    /*color: rgb(156, 156, 156) !important;*/
    
}

.post-image {

    padding: none !important;
    margin: none !important;
}

.post-image ion-img {

    width: 100% !important;
}

.post-content p {

    font-size: 1rem !important;
}

.post-actions {

    /*background-color: white !important;*/
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    border-top: 0.1px solid /*rgb(228, 228, 228)*/ !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.2rem !important;
}

.post-actions ion-col {

    margin-left: -1rem !important;
}

.post-actions ion-col:first-child {

    margin-right: -2rem !important;
}

.post-actions ion-col:last-child {

    margin-right: 2rem !important;
}

.post-actions ion-col ion-icon {

    font-size: 1.4rem !important;
    /*color:rgb(105, 105, 105) !important;*/
}

.post-actions ion-col ion-text {

    font-size: 0.8rem !important;
    /*color: rgb(107, 107, 107) !important;*/
    margin-top: 0.2rem !important;
    margin-left: 0.5rem !important;
    position: absolute !important;
}