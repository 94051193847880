.message-item {
  flex-basis: 100vh;
  padding-top: 0.5rem;
}

.last-online {

  color: rgb(190, 190, 190);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.stats {
  text-align: right;
  margin-top: -1rem;
  margin-right: 0.5rem;
}

.stats ion-badge {

  margin: 0 !important;
  margin-top: -3rem !important;
  border-radius: 2px !important;
}  
  
.contact-details {

  margin-top: 0rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.avatar {

  height: 3.5rem;
  width: 3.5rem;
}